<template>
  <v-container fluid>
    <v-row>
      <v-col class="mb-5" cols="auto" md="12" sm="12">
        <v-card>
          <v-data-table
            :footer-props="{
              pageText: `{0}-{1} ${$t('tables.of')} {2}`,
              itemsPerPageText: $t('tables.pricingRegionsByPage'),
              itemsPerPageOptions: [5, 10, 20, 50, 100],
            }"
            :headers="pricingRegionsHeaders"
            :no-data-text="$t('tables.noDataText')"
            :no-results-text="$t('tables.noDataText')"
            :items="pricingRegions"
            :search="searchString"
            class="elevation-1 cursor-pointer"
            @click:row="editItem"
            multi-sort>
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title>
                  {{ $t('tables.pricingRegions') }}
                </v-toolbar-title>

                <v-divider class="mx-4" inset vertical></v-divider>

                <v-text-field
                  v-model="searchString"
                  append-icon="mdi-magnify"
                  hide-details
                  :label="$t('tables.search')"
                  single-line></v-text-field>

                <v-spacer />

                <v-dialog v-model="pricingRegionDialog" max-width="700px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mb-2" color="primary" dark v-bind="attrs" v-on="on">
                      {{ $t('buttons.add') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t(formTitle) }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingRegionEdit"></v-icon>
                    </v-card-title>

                    <validation-observer ref="form" v-slot="{ handleSubmit, failed }">
                      <v-form lazy-validation @submit.prevent="handleSubmit(savePricingRegion)">
                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="name">
                                  <v-text-field
                                    v-model="editedItem.name"
                                    autofocus
                                    name="name"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.name')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="currency">
                                  <v-autocomplete
                                    v-model="editedItem.currency"
                                    :items="currencies"
                                    clearable
                                    item-text="name"
                                    item-value="name"
                                    :label="$t('pricingRegionsPage.chooseCurrency')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|numeric|max:200"
                                    v-slot="{ errors }"
                                    name="solo_price">
                                  <v-text-field
                                      v-model="editedItem.solo_price"
                                      name="solo_price"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.soloPrice')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|max:200"
                                    v-slot="{ errors }"
                                    name="solo_paddle_price_id">
                                  <v-text-field
                                      v-model="editedItem.solo_paddle_price_id"
                                      name="solo_paddle_price_id"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.soloPaddlePriceId')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|numeric|max:200"
                                    v-slot="{ errors }"
                                    name="team_5_price">
                                  <v-text-field
                                      v-model="editedItem.team_5_price"
                                      name="team_5_price"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.team5Price')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|max:200"
                                    v-slot="{ errors }"
                                    name="team_5_paddle_price_id">
                                  <v-text-field
                                      v-model="editedItem.team_5_paddle_price_id"
                                      name="team_5_paddle_price_id"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.team5PaddlePriceId')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_10_price">
                                  <v-text-field
                                    v-model="editedItem.team_10_price"
                                    name="team_10_price"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team10Price')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_10_paddle_price_id">
                                  <v-text-field
                                    v-model="editedItem.team_10_paddle_price_id"
                                    name="team_10_paddle_price_id"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team10PaddlePriceId')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_25_price">
                                  <v-text-field
                                    v-model="editedItem.team_25_price"
                                    name="team_25_price"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team25Price')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_25_paddle_price_id">
                                  <v-text-field
                                    v-model="editedItem.team_25_paddle_price_id"
                                    name="team_25_paddle_price_id"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team25PaddlePriceId')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_50_price">
                                  <v-text-field
                                    v-model="editedItem.team_50_price"
                                    name="team_50_price"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team50Price')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_50_paddle_price_id">
                                  <v-text-field
                                    v-model="editedItem.team_50_paddle_price_id"
                                    name="team_50_paddle_price_id"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team50PaddlePriceId')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|numeric|max:200"
                                    v-slot="{ errors }"
                                    name="solo_price_monthly">
                                  <v-text-field
                                      v-model="editedItem.solo_price_monthly"
                                      name="solo_price_monthly"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.soloPriceMonthly')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|max:200"
                                    v-slot="{ errors }"
                                    name="solo_paddle_price_id_monthly">
                                  <v-text-field
                                      v-model="editedItem.solo_paddle_price_id_monthly"
                                      name="solo_paddle_price_id_monthly"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.soloPaddlePriceIdMonthly')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|numeric|max:200"
                                    v-slot="{ errors }"
                                    name="team_5_price_monthly">
                                  <v-text-field
                                      v-model="editedItem.team_5_price_monthly"
                                      name="team_5_price_monthly"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.team5PriceMonthly')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                    rules="required|max:200"
                                    v-slot="{ errors }"
                                    name="team_5_paddle_price_id_monthly">
                                  <v-text-field
                                      v-model="editedItem.team_5_paddle_price_id_monthly"
                                      name="team_5_paddle_price_id_monthly"
                                      clearable
                                      counter="200"
                                      :label="$t('pricingRegionsPage.team5PaddlePriceIdMonthly')"
                                      :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_10_price_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_10_price_monthly"
                                    name="team_10_price_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team10PriceMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_10_paddle_price_id_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_10_paddle_price_id_monthly"
                                    name="team_10_paddle_price_id_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team10PaddlePriceIdMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_25_price_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_25_price_monthly"
                                    name="team_25_price_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team25PriceMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_25_paddle_price_id_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_25_paddle_price_id_monthly"
                                    name="team_25_paddle_price_id_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team25PaddlePriceIdMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|numeric|max:200"
                                  v-slot="{ errors }"
                                  name="team_50_price_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_50_price_monthly"
                                    name="team_50_price_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team50PriceMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="6" md="6" sm="12">
                                <validation-provider
                                  rules="required|max:200"
                                  v-slot="{ errors }"
                                  name="team_50_paddle_price_id_monthly">
                                  <v-text-field
                                    v-model="editedItem.team_50_paddle_price_id_monthly"
                                    name="team_50_paddle_price_id_monthly"
                                    clearable
                                    counter="200"
                                    :label="$t('pricingRegionsPage.team50PaddlePriceIdMonthly')"
                                    :error-messages="errors"></v-text-field>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" lg="12" md="12" sm="12">
                                <validation-provider rules="required|max:200" v-slot="{ errors }" name="countries">
                                  <v-autocomplete
                                    multiple
                                    ref="chooseCountries"
                                    v-model="editedItem.countries"
                                    :items="countries"
                                    clearable
                                    item-text="name"
                                    item-value="id"
                                    :label="$t('pricingRegionsPage.chooseCountries')"
                                    :no-data-text="$t('select.noDataAvailable')"
                                    @focus="$event.target.click()"
                                    :error-messages="errors"
                                    @change="onCountrySelect"></v-autocomplete>
                                </validation-provider>
                              </v-col>
                              <v-col cols="12" md="12" sm="12">
                                <validation-provider rules="max:1000" v-slot="{ errors }" name="description">
                                  <v-textarea
                                    v-model="editedItem.description"
                                    clearable
                                    counter="1000"
                                    :label="$t('pricingRegionsPage.description')"
                                    rows="2"
                                    auto-grow
                                    :error-messages="errors"></v-textarea> </validation-provider
                              ></v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>
                        <v-card-actions>
                          <v-spacer />
                          <button-submit :failed="failed" :loading="loading" buttonText="buttons.save"></button-submit>
                        </v-card-actions>
                      </v-form>
                    </validation-observer>
                  </v-card>
                </v-dialog>

                <v-dialog v-model="pricingRegionDialogDelete" max-width="650">
                  <v-card>
                    <v-card-title class="info title white--text font-weight-bold">
                      {{ $t('pricingRegionsPage.deletePricingRegion') }}
                      <v-spacer />
                      <v-icon class="mdi mdi-close" style="color: white" @click="closePricingRegionDelete"></v-icon>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <p>
                          {{ $t('pricingRegionsPage.deleteMessage') }}
                        </p>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer />
                      <v-btn class="primary" text @click="deletePricingRegionItemConfirm">
                        {{ $t('buttons.yes') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-tooltip left>
                    <template v-slot:activator="{ on: tooltipOn }">
                      <v-btn text v-on="{ ...on, ...tooltipOn }">
                        <v-icon> mdi-dots-vertical </v-icon>
                      </v-btn>
                    </template>
                    <span>
                      {{ $t('buttons.actions') }}
                    </span>
                  </v-tooltip>
                </template>
                <v-list>
                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="editItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-pencil</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.edit') }}</span>
                  </v-tooltip>

                  <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-list-item @click="deleteItem(item)">
                        <v-icon class="mx-1" small v-bind="attrs">mdi-trash-can-outline</v-icon>
                        <v-list-item-title v-bind="attrs" v-on="on" class="ml-2">
                          {{ $t('buttons.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                    <span>{{ $t('tooltips.delete') }}</span>
                  </v-tooltip>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import i18n from '@/i18n/i18n';
import { pricingRegionsHeaders } from '@/mixins/data-table-headers';
import { defaultPricingRegion } from '@/mixins/default-items';
import ButtonSubmit from '@/components/buttons/ButtonSubmit.vue';

export default {
  name: 'PricingRegionItem',
  components: { ButtonSubmit },
  data: () => ({
    searchString: '',
    pricingRegionDialog: false,
    pricingRegionDialogDelete: false,
    pricingRegions: [],
    formTitle: 'pricingRegionsPage.newItem',
    editedItem: {},
    editedItemDeleted: {},
    editedItemIndex: null,
    enableSave: true,
    loading: false,
    currencies: [{ name: 'USD' }, { name: 'EUR' }, { name: 'GBP' }],
    countries: [],
  }),
  created() {
    this.editedItem = Object.assign({}, defaultPricingRegion);
    this.loadAllPricingRegions();
    this.loadAllCountries();
  },
  computed: {
    pricingRegionsHeaders() {
      return pricingRegionsHeaders(i18n);
    },
  },
  methods: {
    async loadAllPricingRegions() {
      await this.$store.dispatch('pricingRegions/getAllPricingRegions').then((res) => {
        this.pricingRegions = res.data.map((e) => {
          return e;
        });
      });
    },

    async loadAllCountries() {
      await this.$store.dispatch('pricingRegions/getAllCountries').then((res) => {
        this.countries = res.data.map((e) => {
          return e;
        });
      });
    },

    editItem(item) {
      this.editedItem = this.pricingRegions.indexOf(item);
      this.editedItemIndex = this.pricingRegions.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = 'pricingRegionsPage.editItem';

      this.editedItem.countries = item.countries.map((item) => {
        return parseInt(item.id);
      });

      this.pricingRegionDialog = true;
    },

    deleteItem(item) {
      this.editedItemDeleted = this.pricingRegions.indexOf(item);
      this.editedItemDeleted = Object.assign({}, item);
      this.pricingRegionDialogDelete = true;
    },

    createFormData() {
      let formData = new FormData();

      if (this.editedItem.id) {
        formData.append('_method', 'POST');
        formData.append('id', this.editedItem.id);
      } else {
        formData.append('id', '');
      }

      formData.append('name', this.editedItem.name ? this.editedItem.name : '');
      formData.append('currency', this.editedItem.currency ? this.editedItem.currency : '');
      formData.append('solo_price', this.editedItem.solo_price ? this.editedItem.solo_price : '');
      formData.append(
          'solo_price_monthly',
          this.editedItem.solo_price_monthly ? this.editedItem.solo_price_monthly : ''
      );

      formData.append('team_5_price', this.editedItem.team_5_price ? this.editedItem.team_5_price : '');
      formData.append(
          'team_5_price_monthly',
          this.editedItem.team_5_price_monthly ? this.editedItem.team_5_price_monthly : ''
      );

      formData.append('team_10_price', this.editedItem.team_10_price ? this.editedItem.team_10_price : '');
      formData.append(
        'team_10_price_monthly',
        this.editedItem.team_10_price_monthly ? this.editedItem.team_10_price_monthly : ''
      );

      formData.append('team_25_price', this.editedItem.team_25_price ? this.editedItem.team_25_price : '');
      formData.append(
        'team_25_price_monthly',
        this.editedItem.team_25_price_monthly ? this.editedItem.team_25_price_monthly : ''
      );

      formData.append('team_50_price', this.editedItem.team_50_price ? this.editedItem.team_50_price : '');
      formData.append(
        'team_50_price_monthly',
        this.editedItem.team_50_price_monthly ? this.editedItem.team_50_price_monthly : ''
      );

      formData.append(
          'solo_paddle_price_id',
          this.editedItem.solo_paddle_price_id ? this.editedItem.solo_paddle_price_id : ''
      );
      formData.append(
          'solo_paddle_price_id_monthly',
          this.editedItem.solo_paddle_price_id_monthly ? this.editedItem.solo_paddle_price_id_monthly : ''
      );

      formData.append(
          'team_5_paddle_price_id',
          this.editedItem.team_5_paddle_price_id ? this.editedItem.team_5_paddle_price_id : ''
      );
      formData.append(
          'team_5_paddle_price_id_monthly',
          this.editedItem.team_5_paddle_price_id_monthly ? this.editedItem.team_5_paddle_price_id_monthly : ''
      );

      formData.append(
        'team_10_paddle_price_id',
        this.editedItem.team_10_paddle_price_id ? this.editedItem.team_10_paddle_price_id : ''
      );
      formData.append(
        'team_10_paddle_price_id_monthly',
        this.editedItem.team_10_paddle_price_id_monthly ? this.editedItem.team_10_paddle_price_id_monthly : ''
      );
      formData.append(
        'team_25_paddle_price_id',
        this.editedItem.team_25_paddle_price_id ? this.editedItem.team_25_paddle_price_id : ''
      );
      formData.append(
        'team_25_paddle_price_id_monthly',
        this.editedItem.team_25_paddle_price_id_monthly ? this.editedItem.team_25_paddle_price_id_monthly : ''
      );
      formData.append(
        'team_50_paddle_price_id',
        this.editedItem.team_50_paddle_price_id ? this.editedItem.team_50_paddle_price_id : ''
      );
      formData.append(
        'team_50_paddle_price_id_monthly',
        this.editedItem.team_50_paddle_price_id_monthly ? this.editedItem.team_50_paddle_price_id_monthly : ''
      );

      this.editedItem.countries.map((item) => {
        formData.append('countries[]', item);
      });
      formData.append('description', this.editedItem.description ? this.editedItem.description : '');

      return formData;
    },

    async savePricingRegion() {
      this.enableSave = false;
      this.loading = true;
      let path = 'savePricingRegion';

      if (this.editedItem.id) {
        Object.assign(this.editedItem, { _method: 'POST' });
        path = 'updatePricingRegion';
      }

      let formData = this.createFormData();

      await this.$store
        .dispatch('pricingRegions/' + path, formData)
        .then(() => {
          this.loadAllPricingRegions();
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.savedSuccessfully'), color: 'green' });
          this.closePricingRegionEdit();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.$store
              .dispatch('errorMessages/errorMapper', error.response.data.message)
              .then((e) => this.$store.dispatch('showSnackbar', { text: e, color: 'red' }));
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .finally(() => {
          this.enablePricingPricingSave = true;
          this.loading = false;
        });
    },

    async deletePricingRegionItemConfirm() {
      await this.$store
        .dispatch('pricingRegions/deletePricingRegion', this.editedItemDeleted)
        .then((res) => {
          if (res.success) {
            this.loadAllPricingRegions();
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.deletedSuccessfully'), color: 'green' });
            this.closePricingRegionDelete();
          } else {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        })
        .finally(() => {
          this.closePricingRegionDelete();
        });
    },

    closePricingRegionEdit() {
      this.pricingRegionDialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, defaultPricingRegion);
      });
      this.$refs.form.reset();
      this.formTitle = 'pricingRegionsPage.newItem';
    },

    closePricingRegionDelete() {
      this.pricingRegionDialogDelete = false;
    },

    onCountrySelect() {
      // on country select we must remove search string
      this.$refs.chooseCountries.lazySearch = '';
    },
  },

  watch: {
    pricingRegionDialog(val) {
      val || this.closePricingRegionEdit();
    },
  },
};
</script>
